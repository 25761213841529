/*global chrome*/
import {useEffect, useState} from 'react';
import Attachments from "../Attachments";
import SessionDetails from "../SessionDetails";
import Tools from '../Tools';
import Zendesk from '../Zendesk';
import ScreenSharing from "../ShareScreen";
import { useTranslation } from 'react-i18next';
import {config} from "../../config";
import { ActionTypes, SyncTemplates, TwilioSyncClient } from '@smarterservices/twilio-sync-client';
import liveswitch from 'fm.liveswitch';
import { Client } from '@twilio/conversations';
import "./index.css";
import CryptoJS from 'crypto-js';
import jwtDecode from 'jwt-decode';
import Salesforce from '../Salesforce';
import { LaunchDarkly, FeatureFlag } from 'react-launch-darkly';
import * as atatus from 'atatus-spa';

export default function TabPresenter() {
  // Tab functionality
  const [currentTab, setCurrentTab] = useState("sessionDetails");
  // Translations
  const { t: translation } = useTranslation();
  // Session Start
  const [sessionStart, setSessionStart] = useState({});
  let recordingStarted = null;
  let userSid = null;
  let studentName = null;
  // Twilio
  let syncClient = null;
  const [stateSyncClient, setStateSyncClient] = useState(null);
  const [ studentNotes, setStudentNotes ] = useState('');
  const [ spToken, setSpToken ] = useState('');
  let authorMap = {};
  let unknownProctorNumber = 1;
  let conversationMessages = [];
  let twilioConversation = null;
  // Liveswitch
  let liveswitchClient = null;
  let liveswitchChannel = null;
  let isRecording = false;
  let screenDeviceId = null;
  let videoDeviceId = null;
  let audioDeviceId = null;
  let screenStream = null;
  let cameraStream = null;
  let cameraLocalMedia = null;
  let audioLocalMedia = null;
  let screenLocalMedia = null;
  let cameraConnection = null;
  let screenConnection = null;
  let ghostStarted = false;
  let speechRecognition = null;
  let lastSpeechDetection = 0;
  let resolutionBand = null;
  let shouldStartWorkflowOnUnregister = false;

  // Utility App
  let utilityAppHealthcheckInterval = null;

  const actionTypes = new ActionTypes();

  // Get the query params
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop)
  });

  const token = params.token;
  const decodedToken = jwtDecode(token);
  const sessionSid = params.sessionSid;
  const examSid = params.examSid;
  const installSid = params.installSid;
  const isSystemCheck = params.systemCheck;

  atatus.config('c166b941b611403888833a905ec5c326', {
    console: true,
    consoleTimeline: true,
    customData: {
      sessionSid,
      examSid,
      installSid
    }
  }).install();

  if (token && !spToken) {
    setSpToken(token);
  }

  const tabs = {
    "attachments": <Attachments sessionStart={sessionStart}/>,
    "sessionDetails": <SessionDetails sessionStart={sessionStart} studentNotes={studentNotes} />,
    "tools": <Tools/>,
    "screenSharing": <ScreenSharing />
  }

  /**
   * Function to pause code execution in async functions
   * @param {number} delay - time in milliseconds
   */
  const sleep = (delay) => {
    return new Promise(res => setTimeout(res, delay));
  }

  const hideCalculator = (shouldHide) => {
    const virtualCalculator = document.getElementById('spVirtualCalc');
    const parentTabs = document.getElementById('spTabs');
    stateSyncClient.getSync().then(syncDoc => {
      if (syncDoc?.sessionStart?.exam?.configuration?.virtualCalculatorType && !shouldHide) {
        parentTabs.style.display = 'none';
        virtualCalculator.style.display = 'block';
      } else {
        parentTabs.style.display = 'block';
        virtualCalculator.style.display = 'none';
      }
    });
  }

  const openSupportChat = () => {
    const supportDiv = document.querySelector('div.helpButton')
    if (supportDiv) {
      const minimizedDiv = document.querySelector('.sidebarMinimized');
      if (minimizedDiv) {
        const divChildren = minimizedDiv.childNodes;
        divChildren.forEach(element => {
          if (element.nodeName === 'BUTTON') {
            element.click();
          }
        })
      } else {
        supportDiv.childNodes[0].click()
      }
    } else {
      try {
        window.zE.activate();
      } catch (error) {
        console.error(error);
      }
    }
  }

  const activateAttachmentTab = () => {
    setCurrentTab("attachments");
    hideCalculator(true);
  }

  const activateCommunicationTab = () => {
    openSupportChat();
  }

  const activateSessionDetailsTab = () => {
    setCurrentTab("sessionDetails");
    hideCalculator(true);
  }

  const activateToolsTab = () => {
    setCurrentTab("tools");
    hideCalculator(false);
  }

  const returnToExam = () => {
    if (stateSyncClient) {
      //stateSyncClient.addAction(actionTypes.extension.returnToExam());
      chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
          action: 'returnToExam',
          executor: 'extension',
          type: 'action'
        }});
    }
  }

  const renderSupportChat = (salesforce) => {
    if (salesforce) {
      return <Salesforce sessionStart={sessionStart} params={params} decodedToken={decodedToken} token={spToken} />
    } else {
      return <Zendesk sessionStart={sessionStart} />
    }
  }
  const removeStudentHybrid = async () => {
    try {
      const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/removeStudent`;
      const requestOptions = {
        method: 'POST',
        headers: {
          'token': spToken
        },
        body: JSON.stringify({})
      };
      await fetch(url, requestOptions);
    } catch (error) {
      console.log('Error occurred while attempting to remove student:', error);
    }
  }
  const postLog = async (data, token, urlOverride = null) => {
    const url = urlOverride ?
        urlOverride :
        `${config.apiUrl}/utilities/log`;
    const options = {
      method: 'POST',
      headers:{
        'token': token
      },
      body: JSON.stringify(data)
    };
     await fetch(url, options)
  }
  const updateSessionDisconnectReason = async (reason) => {
    try {
      const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/updateDisconnectReason`;
      const requestOptions = {
        method: 'POST',
        headers: {
          'token': spToken
        },
        body: JSON.stringify({reason: reason})
      };
      await fetch(url, requestOptions);
    } catch (error) {
      console.log('Error occurred while attempting to remove student:', error);
    }
  }

  const stop = async () => {
    try {
      isRecording = false;
      shouldStartWorkflowOnUnregister = false;

      try {
        window.onbeforeunload = (event) => {
          return null;
        }
      } catch (err) {
        console.log('Error removing the onbeforeunload event');
      }

      // Start by setting sessionEnded to true in the sync doc
      syncClient.setSync({ sessionEnded: true });

      // We need to raise the annotation that recording was stopped
      createAnnotation({
        smarterProctoringAnnotationData: {
          title: 'Recording Stop',
          severity: 'green'
        }
      });

      // Send a call to remove the student from a room if they are in one
      await removeStudentHybrid();

      // The utility app was started, so we need to stop it
      if (utilityAppHealthcheckInterval) {
        try {
          stopUtilityAppHealthcheck();
          await stopBackgroundAppsCheck();
        } catch (err) {
          console.log('Error stopping background app', err);
        }
      }

      await screenConnection.close().then(() => {
        console.log('Successfully closed the screen connection');
      }).fail((error) => {
        console.error('Encountered an error trying to close the screen connection:', error);
      });

      await cameraConnection.close().then(() => {
        console.log('Successfully closed the camera connection');
      }).fail((error) => {
        console.error('Encountered an error trying to close the camera connection:', error);
      });

      await liveswitchClient.leave(sessionSid).then(() => {
        console.log('Successfully left Liveswitch channel');
      }).fail((error) => {
        console.error('Encountered an error trying to leave the Liveswitch channel:', error);
      });

      await liveswitchClient.unregister().then(() => {
        console.log('Successfully unregistered the Liveswitch client');
      }).fail((error) => {
        console.error('Encountered an error trying to unregister the Liveswitch client');
      });

      screenStream.getTracks().forEach(track => track.stop());
      screenStream = null;
      cameraStream.getTracks().forEach(track => track.stop());
      cameraStream = null;
    } catch (error) {
      console.error('An unhandled error occurred while stopping Liveswitch:', error);
    }
  }

  const beginRecording = (actionObj) => {
    if (!screenDeviceId && !audioDeviceId && !videoDeviceId) {
      audioDeviceId = JSON.parse(actionObj.audioDeviceId);
      videoDeviceId = JSON.parse(actionObj.videoDeviceId);
      resolutionBand = actionObj.resolutionBand;
      console.log('Resolution Band:', resolutionBand);

      const getChromeIdFromExtension = () => {
        //syncClient.addAction(actionTypes.extension.getChromeDesktopId('session'));
        const action = {
          action: 'getChromeDesktopId',
          executor: 'extension',
          type: 'action',
          respondTo: 'session'
        }
        chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: action }, actionResponse => {
          // if an action has a "respondTo" field, that means that we need to put
          // the response object in the action array that correlates to the respondTo
          // field. However, we also need to make sure that the "respondTo" is not
          // the same as the "executor" or this could create an endless cycle
          // (meaning, an executor cannot respond to itself... because that would trigger
          // another action)
          if (actionResponse && action.respondTo && action.respondTo !== action.executor) {
            const responseAction = {
              action: action.action,
              executor: action.respondTo,
              response: actionResponse,
              type: 'response'
            }
            startRecording(responseAction);
          }
        });

      }
      setTimeout(getChromeIdFromExtension, 300);
      chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__updateVideoDeviceId', videoDevice: videoDeviceId });
      // Now that we are sharing the screen, lets raise the annotation
      createAnnotation({
        smarterProctoringAnnotationData: {
          title: 'Screen Share',
          severity: 'green'
        }
      });
    }
  }

  const getLiveswitchClientAndJoinChannel = async () => {
    try {
      // START LIVESWITCH
      liveswitchClient = new liveswitch.Client("https://cloud.liveswitch.io/sync", // Gateway URL
          config.liveswitchApplicationId, // Application ID
           userSid, // User ID
          '', // Device ID
          null,
          []); // Role

      const url = `${config.apiUrl}/liveswitch/register/token?sessionSid=${sessionSid}`;
      const requestOptions = {
        headers: {
          'token': token
        }
      };
      const response = await fetch(url, requestOptions);
      const registerToken = await response.json();

      liveswitchClient.addOnStateChange(() => {
        if (shouldStartWorkflowOnUnregister) {
          try {
            const clientState = liveswitchClient.getState();
            if (clientState === liveswitch.ClientState.Unregistered) {
              const logObject = {
                log: 'Student liveswitch client unregistered',
                logType: 'info',
                label: 'Liveswitch Client',
                object: {
                  sessionSid,
                  examSid,
                  installSid,
                  userSid
                }
              }
              const overrideUrl = `${config.postLogUrlOverride}?sessionSid=${sessionSid}`;
              postLog(logObject, spToken, overrideUrl);
            }
          } catch (ex) {
            console.log('Caught an error logging a state change');
          }
        }
      });

      await liveswitchClient.register(registerToken.liveswitchToken);

      await liveswitchClient.join(sessionSid, registerToken.liveswitchToken).then((channel) => {
        shouldStartWorkflowOnUnregister = true;
        liveswitchChannel = channel;
      }).fail((error) => {
        shouldStartWorkflowOnUnregister = false;
        console.error('Could not join channel', error);
        return false;
      });

      return true;
    } catch (error) {
      console.error('Error occurred while registering client and joining channel', error);
      return false;
    }
  }

  const openLocalMediaWithRetry = async (localMedia, mediaType) => {
    const numberOfRetries = 5;
    let attemptNumber = 0;
    let started = false;
    while (!started && attemptNumber < numberOfRetries) {
      attemptNumber++;
      try {
        // eslint-disable-next-line
        await localMedia.start();
        console.log(`Successfully started ${mediaType} media`);
        started = true;
      } catch (error) {
        console.log(`Attempt: ${attemptNumber}/5 - Error while starting ${mediaType} media`, error);
        if (attemptNumber === numberOfRetries) {
          console.log(`Unable to start ${mediaType}`);
        }
      }
    }
    return started;
  }

  const openScreenConnection = async () => {
    // Build our local media object
    screenLocalMedia = new liveswitch.LocalMedia(false, screenStream);

    // Create the stream to pass to our SFU connection
    const liveswitchScreenStream = new liveswitch.VideoStream(screenLocalMedia, null);

    // Create the screen connection
    screenConnection = liveswitchChannel.createSfuUpstreamConnection(null, liveswitchScreenStream, null, "ScreenConnection");

    // Start the screen local media with retry
    const screenOpened = await openLocalMediaWithRetry(screenLocalMedia, 'screen');
    console.log('Opened Screen with retry:', screenOpened);
    if (!screenOpened) {
      return false;
    }

    // Now we need to create a listener to see if our screen connection is failing. If it is, we will destroy ALL of the
    // local media objects. However, we will NOT rebuild them here. We already have a listener on the camera connection,
    // so if we destroy the local media objects, that listener will get tripped and do the rebuilding.
    screenConnection.addOnStateChange(connection => {
      if (connection.getState() === liveswitch.ConnectionState.Closing ||
          connection.getState() === liveswitch.ConnectionState.Failing) {
        const status = connection.getState() === liveswitch.ConnectionState.Closing ?
            'Closing' :
            'Failing';
        console.log(`Liveswitch Status: ${status}`);
        try {
          audioLocalMedia.destroy();
          cameraLocalMedia.destroy();
          screenLocalMedia.destroy();
        } catch (error) {
          console.error('Error occurred destroying local media objects', error);
        }
      }
    });

    // listener was setup, and the media was opened, so lastly we will open the connection
    return screenConnection.open().then(() => {
      console.log('Successfully opened Screen Connection!');
      return true;
    }).fail((error) => {
      console.log('Could not open Screen Connection.', error);
      return false;
    });
  }

  const openCameraConnection = async () => {
    // Build our local media objects
    cameraLocalMedia = new liveswitch.LocalMedia(true, cameraStream);
    audioLocalMedia = new liveswitch.LocalMedia(true, false);

    // Next, we will make the liveswitch streams to pass in to create our SFU connection
    const liveswitchAudioStream = new liveswitch.AudioStream(audioLocalMedia, null);
    const liveswitchCameraStream = new liveswitch.VideoStream(cameraLocalMedia, null);

    // Create the camera connection
    cameraConnection = liveswitchChannel.createSfuUpstreamConnection(liveswitchAudioStream, liveswitchCameraStream, null, "WebcamConnection");

    // Lets start the local media with a retry for the camera
    const cameraOpened = await openLocalMediaWithRetry(cameraLocalMedia, 'webcam');
    console.log('Opened Camera with retry:', cameraOpened);
    if (!cameraOpened) {
      return false;
    }
    // And now start the audio
    const audioOpened = await openLocalMediaWithRetry(audioLocalMedia, 'audio');
    console.log('Opened audio with retry:', audioOpened);
    if (!audioOpened) {
      return false;
    }

    // We hav already opened the media to Liveswitch, but they ALWAYS default to the default audio.  We needed to open the local media
    // first, because that gives Liveswitch access to the devices.  So if we are not planning on using the default audio,
    // we need to open up the devices and select the proper audio device
    if (audioDeviceId.deviceId !== 'default') {
      // First, we need to get the list of all available audio source inputs, but it is VERY important to note that the
      // deviceIds that we get from these sources DO NOT match the deviceIds we will get from calling enumerateDevices
      // so, we will have to try matching based of the device name... otherwise stick to the default audio
      const liveswitchAudioSources = await audioLocalMedia.getAudioSourceInputs();
      let selectedSource = null;
      // iterate over each source that liveswitch has to see if it matches the name of the selected audio device from onboarding
      for (const audSource of liveswitchAudioSources) {
        if (audSource.getName().includes(audioDeviceId.name)) {
          selectedSource = audSource;
        }
      }
      // If we have a match, we need to call to change the audio source input, which ends the old audio and starts a new input source
      // We have not started recordings yet though, so changing this will not affect the quality of the session's video because
      // this all happens before that starts
      if (selectedSource) {
        audioLocalMedia.changeAudioSourceInput(selectedSource);
      }
    }

    // Now that connections are created and the media was started, lets create a listener for if our connection is to fail
    // If it does fail, we need to destroy ALL local media objects and recreate them
    cameraConnection.addOnStateChange(connection => {
      if (connection.getState() === liveswitch.ConnectionState.Closing ||
          connection.getState() === liveswitch.ConnectionState.Failing) {
        // If we get in here, the status is not healthy, and we want to destroy the media objects and rebuild them
        // Log out the status
        const status = connection.getState() === liveswitch.ConnectionState.Closing ?
            'Closing' :
            'Failing';
        console.log(`Liveswitch Status: ${status}`);

        // Now we will destroy ALL the media and rebuild them. We need to destroy them all so that the recordings stay as
        // close to the same length as possible
        try {
          audioLocalMedia.destroy();
          cameraLocalMedia.destroy();
          screenLocalMedia.destroy();
        } catch (error) {
          console.error('Error occurred destroying local media objects', error);
        }
      } else if (connection.getState() === liveswitch.ConnectionState.Failed && isRecording) {
        console.log('Liveswitch Status: Failed... Starting to build connections again');
        // Call start recording again... which requires the screen device ID, so we will build an object that it can use
        startRecording({ response: screenDeviceId });
      } else if (connection.getState() === liveswitch.ConnectionState.Closed && isRecording) {
        console.log('Liveswitch Status: Closed... Starting to build connections again');
        startRecording({ response: screenDeviceId });
      }
    });

    // Everything is setup to listen to the connection, and the media was opened, so lets open the connection
    return cameraConnection.open().then(() => {
      console.log('Successfully opened Camera Connection!');
      return true;
    }).fail((error) => {
      console.log('Could not open Camera Connection.', error);
      return false;
    });
  }

  const startGhostProcessor = async () => {
    const url = `${config.apiUrl}/session/${sessionSid}/liveswitch/started`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'token': token
      },
      body: JSON.stringify({})
    };
    await fetch(url, requestOptions);
  }

  const startRecording = async (actionObj) => {
    if (actionObj.response && actionObj.response !== 'UserCancelledSpScreenShare') {
      screenDeviceId = actionObj.response;

      let successfulStart = false;
      const timeToWait = 120000;
      const timeStarted = new Date().getTime();
      isRecording = true;
      while (!successfulStart && (new Date().getTime() - timeStarted) < timeToWait) {
        try {
          // Now that we have the devices to use, lets start by setting up the stream for the screen
          // We NEED to do this first, because the screenDeviceId is sent from the extension, and that
          // ID is destroyed if not used in a few seconds
          if (!screenStream) {
            screenStream = await navigator.mediaDevices.getUserMedia({
              video: {
                mandatory: {
                  chromeMediaSource: 'desktop',
                  chromeMediaSourceId: actionObj.response,
                  maxFrameRate: 3
                }
              }
            });
            // This is an event listener for if the user clicks the "Stop Sharing" button in the chrome UI
            // eslint-disable-next-line
            screenStream.getVideoTracks()[0].addEventListener('ended',  () => {
              stop().then(() => {
                updateSessionDisconnectReason('test-taker-ended');
                createAnnotation({
                  smarterProctoringAnnotationData: {
                    title: 'User Disconnected',
                    severity: 'green',
                    metadata: {
                      reason: 'User Clicked the Chrome Stop Sharing Button'
                    }
                  }
                });
                // Leave end session actions
                syncClient.addAction(actionTypes.extension.endSession("ChromeStoppedSharing"));
              });
            });
          }

          // 1 second delay between attempts so that computers can handle the retry
          await sleep(1000);

          // Now we need to setup the camera
          if (!cameraStream) {
            const tempStreamForPerms = await navigator.mediaDevices.getUserMedia({
              audio: false,
              video: true
            });
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            let usersDevice = null;
            // eslint-disable-next-line
            mediaDevices.forEach((mediaDevice) => {
              if (mediaDevice.kind === 'videoinput' &&
                  (mediaDevice.deviceId === videoDeviceId.deviceId || mediaDevice.label === videoDeviceId.name)) {
                usersDevice = mediaDevice;
              }
            });
            tempStreamForPerms.getTracks().forEach((track) => {
              track.stop();
            });

            // Set all users to use standard quality resolution
            const videoConstraints = {
              width: { max: 640 },
              height: { max: 480 }
            };

            if (usersDevice) {
              videoConstraints.deviceId = usersDevice.deviceId;
            }
            cameraStream = await navigator.mediaDevices.getUserMedia({
              audio: { deviceId: audioDeviceId.deviceId },
              video: videoConstraints
            });
          }

          // Now that we have both streams, lets open each connection to begin the recording
          // First, we need to get the liveswitch client and join the channel
          const joined = await getLiveswitchClientAndJoinChannel();
          if (!joined) {
            continue;
          }

          try {
            // Now that we have joined, we need to open the camera connection
            const cameraConnected = await openCameraConnection();
            console.log('Camera Connected:', cameraConnected);
            if (!cameraConnected) {
              throw new Error('Camera not connected');
            }
            // And then the screen connection
            const screenConnected = await openScreenConnection();
            console.log('Screen Connected:', screenConnected);
            if (!screenConnected) {
              throw new Error('Screen not connected');
            }
          } catch (error) {
            // try to destroy all local media before trying to restart the connections
            try {
              cameraLocalMedia.destroy();
            } catch (error) {
              // no-op
            }
            try {
              audioLocalMedia.destroy();
            } catch (error) {
              // no-op
            }
            try {
              screenLocalMedia.destroy();
            } catch (error) {
              // no-op
            }
            continue;
          }

          // Next we need to setup a listener for a proctor call
          // eslint-disable-next-line
          liveswitchChannel.addOnRemoteUpstreamConnectionOpen((remoteConnectionInfo) => {
            console.log('Receiving a call from the proctor');
            const remoteMedia = new liveswitch.RemoteMedia();
            const audioStream = new liveswitch.AudioStream(null, remoteMedia);

            const proctorCallConnection = liveswitchChannel.createSfuDownstreamConnection(remoteConnectionInfo, audioStream);

            proctorCallConnection.open().then(() => {
              console.log('Proctor Call Connected!');
            });
          });

          if (!ghostStarted) {
            ghostStarted = true;
            await startGhostProcessor();
            // Now that everything is shared and the ghost is started, lets alert the onboarding UI that the student
            // shared their screen and can move forward
            syncClient.addAction(actionTypes.onboarding.userSharedScreen(true));
            // Since this is the first time starting the recording, we need to create the annotation that recording has
            // started
            createAnnotation({
              smarterProctoringAnnotationData: {
                title: 'Recording Start',
                severity: 'green'
              }
            });
          }
          successfulStart = true;
          // Now that everything is started, lets setup speech detection
          startSpeechDetection();
        } catch (error) {
          console.error('Error setting up recording', error);
          successfulStart = false;
        }
      }

      if (!successfulStart) {
        // Close loader and alert the user of the error
        const actions = [
          actionTypes.onboarding.userSharedScreen(false),
          actionTypes.onboarding.passErrorToOnboarding('An error occurred while sharing your screen, contact SmarterProctoring support for assistance.')
        ];
        syncClient.addActions(actions)
      }
    } else {
      // user cancelled the chrome share screen UI, so we need to reset everything to allow another attempt
      audioDeviceId = null;
      screenDeviceId = null;
      videoDeviceId = null;
      // Alert onboarding UI to close loader
      syncClient.addAction(actionTypes.onboarding.userSharedScreen(false));
    }
  }

  const startSpeechDetection = () => {
    try {
      speechRecognition = new window.webkitSpeechRecognition();

      speechRecognition.continuous = true;
      // Speech recognition will run for 60 seconds, then it will end... so we just have to keep starting it after it stops!
      speechRecognition.onend = () => {
        speechRecognition.continuous = true;
        speechRecognition.start();
      }
      speechRecognition.onerror = event => {
        // Do nothing, but catch these here because we will get an error if we start up speech recognition after not having
        // a speech event the previous attempt
      }
      speechRecognition.onresult = async event => {
        if (!process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === 'dev') {
          console.log('Speech Detected:', event);
        }
        const now = new Date().getTime();
        if (now - lastSpeechDetection > 59999) {
          // Last speech detection was over a minute ago, so we can throw it again
          lastSpeechDetection = now;
          createAnnotation({
            smarterProctoringAnnotationData: {
              title: 'Speech Detected',
              severity: 'red'
            }
          });
        }
      }
      speechRecognition.start();
    } catch (error) {
      console.log('Error while starting speech recognition', error);
    }
  }

  const createTaskForStudent = async (actionObj) => {
    const url = `${config.apiUrl}/hybrid/students/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/createTask`;
    const requestOptions = {
      method: 'PUT',
      headers: {
        'token': token,
        'Content-type': 'application/json'
      },
      body: JSON.stringify({
        sessionSid: actionObj.sessionSid,
        installSid: actionObj.installSid,
        examSid: actionObj.examSid,
        courseSid: actionObj.courseSid,
        doesAllowAnyProctor: actionObj.doesAllowAnyProctor,
        sessionInfo: {
          student: studentName,
          sessionSid: sessionSid,
          installSid: installSid
        }
      })
    };
    await fetch(url, requestOptions);
  }

  const getServerTime = async () => {
    const url = `${config.apiUrl}/currentTime`;
    const requestOptions = {
      method: 'GET',
      headers: {
        'token': spToken
      }
    };
    const response = await fetch(url, requestOptions);
    const jsonResponse = await response.json();
    return jsonResponse.currentTime;
  }

  const getSessionTimeSeconds = async () => {
    if (recordingStarted) {
      // This means that we have set the starting time locally, so we can use that datetime for finding the amount of
      // seconds into the exam
      const now = new Date(await getServerTime());
      const start = new Date(recordingStarted);
      return Math.round((now - start) / 1000);
    }
    // we did not yet set the recording started time, so lets check the sync doc if it was started. Otherwise, we will
    // just return the time of 0
    const docData = await syncClient.getSync();
    if (docData && docData.recording_started) {
      recordingStarted = docData.recording_started;
      const now = new Date(await getServerTime());
      const start = new Date(recordingStarted);
      return Math.round((now - start) / 1000);
    }
    // The recording started time is not in the sync doc... so we will set it for time 0
    return 0;
  }

  const createAnnotation = async (actionObj) => {
    const annotationData = actionObj?.smarterProctoringAnnotationData;
    if (!annotationData) return;
    // First thing we need to do is find the time into the session. We will be using the `??` operator here. This operator
    // functions very similarly to the `||` operator, except that it is meant to be used on integer values. Passing in a zero
    // is considered "falsey", so if we were to use the pipe `||` operator, it would skip over the zero. The `??` operator will not
    const sessionTime = annotationData.sessionTime ?? await getSessionTimeSeconds();
    try {
      // Setup the annotation object with default values for any not defined
      const body = {
        severity: annotationData.severity || "yellow",
        type: annotationData.type || "manual",
        title: annotationData.title,
        sessionTime,
        details: annotationData.details || null,
        metadata: annotationData.metadata || null,
        createdBy: annotationData.createdBy || "system",
        status: annotationData.status || "pending review"
      };
      const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/annotations`;
      const requestOptions = {
        method: 'POST',
        headers: {
          'token': spToken
        },
        body: JSON.stringify(body)
      };
      await fetch(url, requestOptions);
    } catch (error) {
      console.error('Error Raising Annotation:', {error, annotationData});
    }
  };

  const startKillingApps = async () => {
    const url = 'http://localhost:58729/startKilling';
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({})
    };
    await fetch(url, requestOptions);
  }

  const startBackgroundAppsCheck = async () => {
    const url = `http://localhost:58729/start/${spToken}/${sessionSid}/${installSid}/${examSid}`;
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({})
    };
    await fetch(url, requestOptions);
  };

  const stopBackgroundAppsCheck = async () => {
    const url = 'http://localhost:58729/stop';
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({})
    };
    await fetch(url, requestOptions);
  };

  const utilityAppHealthcheck = async () => {
    try {
      const url = 'http://localhost:58729/';
      const requestOptions = { method: 'GET' };
      await fetch (url, requestOptions);
    } catch (error) {
      // On an error, it means the shim app is not responsive, so lets create an annotation that is is not connected
      createAnnotation({
        smarterProctoringAnnotationData: {
          title: 'Utility App Not Connected',
          severity: 'red'
        }
      });
      // Now we will try restarted the utility app in case the student tries starting it again
      try {
        await startBackgroundAppsCheck();
        await startKillingApps();
        // If we do not error out from restarting, lets annotate that the utility app was successfully restarted
        createAnnotation({
          smarterProctoringAnnotationData: {
            title: 'Utility App Reconnected',
            severity: 'green'
          }
        });
      } catch (err) {
        console.log('Utility App is not running...');
      }
    }
  };

  const startUtilityAppHealthcheck = () => {
    // Start the healthcheck every ten seconds
    utilityAppHealthcheckInterval = setInterval(utilityAppHealthcheck, 10000);
  };

  const stopUtilityAppHealthcheck = () => {
    if (!utilityAppHealthcheckInterval) return;
    clearInterval(utilityAppHealthcheckInterval);
  }

  const chromeExtensionMessageListener = (request) => {
    // below we have a switch that looks at the STRING values that are associated with certain requests. However, if we are
    // raising an annotation, we will be sending over a JSON object of the annotation, so we need to first check if it is
    // and annotation that we need to raise from the extension.
    if (typeof request.data === "object" && request.data.smarterProctoringAnnotationData) {
      createAnnotation(request.data);
      return;
    }
    // We will also send focus requests the same way as annotations
    if (typeof request.data === "object" && request.data.smarterProctoringSessionTabRequested) {
      const tabToFocus = request.data.smarterProctoringSessionTabRequested || "sessionDetails";
      const hideVirtualCalculator = (shouldHide) => {
        const virtualCalculator = document.getElementById('spVirtualCalc');
        const parentTabs = document.getElementById('spTabs');
        syncClient.getSync().then(syncDoc => {
          if (syncDoc?.sessionStart?.exam?.configuration?.virtualCalculatorType && !shouldHide) {
            parentTabs.style.display = 'none';
            virtualCalculator.style.display = 'block';
          } else {
            parentTabs.style.display = 'block';
            virtualCalculator.style.display = 'none';
          }
        });
      }
      switch (tabToFocus) {
        case 'attachments':
          setCurrentTab("attachments");
          hideVirtualCalculator(true);
          break;
        case 'communication':
          activateCommunicationTab();
          break;
        case 'sessionDetails':
          setCurrentTab("sessionDetails");
          hideVirtualCalculator(true);
          break;
        case 'tools':
          setCurrentTab("tools");
          hideVirtualCalculator(false);
          break;
        case 'screenSharing':
          setCurrentTab("screenSharing");
          hideVirtualCalculator(true);
          break;
        default:
          break;
      }
      return;
    }

    if (typeof request.data === "object" && request.data.smarterProctoringChatMessage) {
      if (!twilioConversation) return;
      twilioConversation.sendMessage(request.data.smarterProctoringChatMessage);
    }
    if (typeof request.data === "object" && request.data.logObject) {
      postLog(request.data.logObject, spToken)
    }
    if (typeof request.data === "object" && request.data.smarterProctoringTabRequestsChatMessages) {
      const requestingTabId = request.data.smarterProctoringTabRequestsChatMessages;
      if (!twilioConversation) return;
      twilioConversation.getMessages().then((messages) => {
        convertAllMessagesToArray(messages).then((conversationMessages) => {
          chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: actionTypes.extension.initializeAllChatMessages(conversationMessages, requestingTabId) });
        });
      });
    }

    switch(request.data) {

      case 'sp_session__endSession':
        stop().then( () => {
          // To get here, a student had to click the stop button normally, so we will raise the annotation that the
          // session ended in a normal fashion

          createAnnotation({
            smarterProctoringAnnotationData: {
              title: 'User Disconnected',
              severity: 'green',
              metadata: {
                reason: 'User Stopped Session'
              }
            }
          }).then(() => {
            // leave end session actions
            syncClient.addAction(actionTypes.extension.endSession());
          }).then(() => {
            updateSessionDisconnectReason('test-taker-ended');
          })
        });
        break;
      case 'sp_session__applyModalityOptions':
        syncClient.getSync().then((syncDoc) => {
          //syncClient.addAction(actionTypes.extension.applyModalityOptions([...syncDoc.currentModalityOptions]));
          if (syncDoc.currentModalityOptions) {
            chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
                action: 'applyModalityOptions',
                modalityOptions: [...syncDoc.currentModalityOptions],
                executor: 'extension',
                type: 'action'
              }});
          }
        });
        break;
      case 'sp_session__injectPassword':
        syncClient.getSync().then((syncDoc) => {
          //syncClient.addAction(actionTypes.extension.injectPassword());
          chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
              action: 'injectPassword',
              executor: 'extension',
              type: 'action',
              syncDocField: syncDoc.sessionStart?.decryptedPassword
            }});
          createAnnotation({
            smarterProctoringAnnotationData: {
              title: 'Password Injected',
              severity: 'green',
              metadata: {
                password: syncDoc.sessionStart?.decryptedPassword,
                method: 'manual'
              }
            }
          });
        });
        break;
      case 'sp_session__injectPasswordAutomatic':
        syncClient.getSync().then((syncDoc) => {
          //syncClient.addAction(actionTypes.extension.injectPassword());
          chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
              action: 'injectPassword',
              executor: 'extension',
              type: 'action',
              syncDocField: syncDoc.sessionStart?.decryptedPassword
            }});
          createAnnotation({
            smarterProctoringAnnotationData: {
              title: 'Password Injected',
              severity: 'green',
              metadata: {
                password: syncDoc.sessionStart?.decryptedPassword,
                method: 'automatic'
              }
            }
          });
        });
        break;
      default:
        break;
    }
  };

  // TWILIO CONVERSATION FUNCTIONS
  const addAuthorToMap = (proctorUserSid, firstName="Proctor", lastInitial=null) => {
    if (!lastInitial) {
      lastInitial = unknownProctorNumber;
      unknownProctorNumber++;
    }
    authorMap[proctorUserSid] = { firstName: firstName, lastInitial: lastInitial };
  };
  const addChatAuthorFromDb = async (author) => {
    try {
      const authorUrl = `${config.apiUrl}/twilio/conversations/chat/author/${author}`;
      const authorOptions = {
        method: 'GET',
        headers: {
          'token': spToken
        }
      };
      const response = await fetch(authorUrl, authorOptions);
      const responseJson = await response.json();

      addAuthorToMap(responseJson.author, responseJson.firstname, responseJson.lastinitial);
    } catch (error) {
      console.error('Error getting chat author from API', error);
    }
  };
  const getAuthorNameString = async (unformattedAuthor) => {
    let author = unformattedAuthor;
    if (unformattedAuthor.startsWith('PU') && unformattedAuthor.length === 68) {
      author = unformattedAuthor.substring(0,34);
    }
    if (author === sessionSid) {
      return 'You';
    }
    if (authorMap[author]) {
      const authorInfo = authorMap[author];
      return `${authorInfo.firstName} ${authorInfo.lastInitial}.`;
    } else {
      // Add the author to our chat map
      await addChatAuthorFromDb(author);
      // Get the author from the map now that it exists
      // We get it from here so that it is formatted the same as in the map
      const authorInfo = authorMap[author];
      return `${authorInfo.firstName} ${authorInfo.lastInitial}.`;
    }
  };
  const processConversationPage = async (currentPage) => {
    let messagesArray = [];
    let itemsToIterate = currentPage.items;

    for (let item of itemsToIterate) {
      if (!item.state.body.startsWith('<<>>*')) {
        const author = await getAuthorNameString(item.state.author);
        messagesArray.push({
          twilioMessage: item.state.body,
          author: author,
          timestamp: item.state.timestamp
        });
      }
    }
    return messagesArray;
  };
  const convertAllMessagesToArray = async (messagesToFormat) => {
    let currentPage = messagesToFormat;
    let messagesArray = [];
    let shouldContinue = true;

    while (shouldContinue) {
      const currentPageMessages = await processConversationPage(currentPage);
      messagesArray = [...currentPageMessages, ...messagesArray];
      if (currentPage.hasPrevPage) {
        currentPage = await currentPage.prevPage();
      } else {
        shouldContinue = false;
      }
    }
    return messagesArray;
  };

  let environment = 'develop';
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    environment = 'production';
  }

  const ldConfig = {
    key: decodedToken.userSid,
    custom: {
      domain: window.location.hostname,
      environment,
      role: 'learner',
      installSid,
      deploymentSid: decodedToken.deploymentSid,
      enrollmentSid: decodedToken.enrollmentSid,
      courseSid: decodedToken.courseSid,
      // integrationSid: userData?.integrationSid,
      userAgent: navigator.userAgent
    }
  }

  useEffect(() => {
    // return a string from onbeforeunload so chrome will raise a popup if a user tries to restart or exit the session tab
    window.onbeforeunload = (event) => {
      return "Please do not navigate away or refresh this page. Doing so will end your session";
    }
    // get session start for the details page
    const getSessionStart = async () => {
      const url = `${config.apiUrl}/installs/${installSid}/exams/${examSid}/sessions/${sessionSid}/start`;
      const requestOptions = {
        method: 'GET',
        headers: {
          'token': token
        }
      };
      const response = await fetch(url, requestOptions);
      return await response.json();
    }

    const getExamConfiguration = async (courseSid, configurationSid) => {
      const url = `${config.apiUrl}/installs/${installSid}/courses/${courseSid}/exams/${examSid}/configurations/${configurationSid}`;
      const options = {
        method: 'GET',
        headers: {
          token
        }
      };

      const response = await fetch(url, options);
      return await response.json();
    }

    const refreshSpToken = async () => {
      const newToken = await getSPToken();
      setSpToken(newToken);

      // Update the token again 10 seconds before it expires
      setTimeout(refreshSpToken, 3590000);
    }

    const checkTTL = async () => {
      const url = `${config.apiUrl}/ttl`;
      const options = {
        method: 'GET',
        headers: { token: spToken }
      }

      const response = await (await fetch(url, options)).json();
      if (response.ttl && response.ttl > 10) {
        setTimeout(refreshSpToken, ((response.ttl * 1000) - 10000))
      } else {
        refreshSpToken();
      }
    }

    const getSPToken = async () => {
      const url = `${config.apiUrl}/authentication/generatetoken`;
      const body = {
        appInstallSid: installSid,
        userSid: decodedToken.userSid
      };
      const options = {
        method: 'POST',
        body: JSON.stringify(body)
      };

      const response = await (await fetch(url, options)).json();

      return response.token;
    }

    const getSessionSyncToken = async () => {
      const url = `${config.apiUrl}/twilio/sync/token`;
      const options = {
          method: 'POST',
          body: JSON.stringify({ identity: sessionSid }),
          headers: { token: spToken }
      };

      const response = await (await fetch(url, options)).json();
      return response.token;
    }

    getSessionStart().then(sessionInfo => {
      // Find TTL for token and set up timeout to refresh it
      checkTTL();
      // We don't send student notes in sessionStart so get them from the exam config
      getExamConfiguration(sessionInfo.courseSid, sessionInfo.exam.configuration.sid).then(examConfig => {
        setStudentNotes(examConfig.studentNotes);
      })
      // Set the session start object
      setSessionStart(sessionInfo);
      // Set the user sid to be used for the liveswitch client
      // eslint-disable-next-line
      userSid = sessionInfo.user.sid;
      // set the student name to be used in the taskrouter task
      // eslint-disable-next-line
      studentName = `${sessionInfo.user.firstName} ${sessionInfo.user.lastName}`;

      // We need to enable buttons on the floating menu if a virtual calculator is enabled, or if there are attachments
      const attachments = sessionInfo?.exam?.configuration?.attachments || [];
      const virtualCalculatorType = sessionInfo?.exam?.configuration?.virtualCalculatorType || null;
      if (attachments.length > 0 || virtualCalculatorType) {
        const buttonsToEnable = {
          attachments: false,
          virtualCalculator: false
        };
        if (attachments.length > 0) {
          buttonsToEnable.attachments = true;
        }
        if (virtualCalculatorType) {
          buttonsToEnable.virtualCalculator = true;
        }

        chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
            action: 'enableFloatingMenuButtons',
            buttonsToEnable,
            executor: 'extension',
            type: 'action'
        }});
      }

      // Now, lets get the calculator ready. We have to build this on the parent so it does not rerender every time
      // we navigate to the "Tools" tab
      // We need to make sure we do not display the calculator unless we click the tools tab
      const virtualCalculator = document.getElementById('spVirtualCalc');
      virtualCalculator.style.display = 'none';
      if (virtualCalculatorType) {
        // a virtual calculator is enabled on the exam, so lets start initializing ClassCalc
        // We need two functions to be called after the script is loaded, so we will create them as const functions
        const retryLimit = 20;
        let attemptNumber = 0;
        const setCalculatorType = (calculatorType) => {
          const { ClassCalc } = window;

          setTimeout(() => {
            try {
              ClassCalc.controller.disableButtons(calculatorType);
            } catch (e) {
              if (attemptNumber < retryLimit) {
                setCalculatorType(calculatorType);
                attemptNumber++;
              } else {
                console.error('ClassCalc failed to initialize.');
              }
            }
          }, 100);
        };
        const renderCalculator = () => {
          const { ClassCalc } = window;
          if (!ClassCalc) return;
          const calcArray = ['scientific-calc', 'graphing-calc', 'matrix-calc', 'four-function-calc'];
          const allowedCalcTypeIndex = calcArray.indexOf(virtualCalculatorType);
          if (allowedCalcTypeIndex > -1) {
            calcArray.splice(allowedCalcTypeIndex, 1);
          }
          ClassCalc.init('calculator', {apiKey: config.classCalcApiKey, userId: userSid});
          setCalculatorType(calcArray);
        };
        // First, lets create the script from their API that is hosted
        const classCalcjs = document.createElement('script');
        classCalcjs.id = 'classCalc-js';
        classCalcjs.src = 'https://app.classcalc.com/api';
        classCalcjs.onload = () => renderCalculator();

        document.body.append(classCalcjs);
      }

      // Before we setup the sync client, we need to decrypt the exam password so that we can inject it properly.
      sessionInfo.decryptedPassword = CryptoJS.AES.decrypt(
          sessionInfo.exam.configuration.password,
          sessionInfo.session.sid + sessionInfo.exam.configuration.sid
      ).toString(CryptoJS.enc.Utf8);

      // if we do not have a sync client, lets create one!
      if (!syncClient) {
        const setupSyncClient = async() => {
          const identity = isSystemCheck ?
              `systemCheck_${sessionInfo.session.sid}` :
              `${sessionInfo.session.sid}`;
          // eslint-disable-next-line
          syncClient = await new TwilioSyncClient({
            baseUri: `${config.apiUrl}`,
            documentId: `Session_${sessionInfo.session.sid}`,
            identity,
            token: `${token}`,
            initialData: SyncTemplates.session(sessionInfo)
          });
          setStateSyncClient(syncClient);
          // Now that we have our sync client, lets setup the event listeners

          // When the extension gets an action, this UI will message it to the extension and return any responses necessary
          // The extension does not have a sync client because it does not have long living scripts, so this will be the
          // logical head of the extension
          // START EXTENSION ACTIONS
          syncClient.getEventEmitter().on("extensionActionsAdded", function(actions) {
            // We receive actions as an array, so lets loop through the actions and send them in FIFO order
            // TODO: Add smarter logging for when an action fails
            for (const action of actions) {
              if (action.action === 'endSession' && action.endPopup &&
                  (action.endPopup === 'ErrorDialog' || action.endPopup === 'ForceClosedByProctor')) {
                // In these cases, we need to still call stop on the session UI to remove the student from the proctor room
                // eslint-disable-next-line
                stop().then(() => {
                  updateSessionDisconnectReason('proctor-ended');
                  createAnnotation({
                    smarterProctoringAnnotationData: {
                      title: 'User Disconnected',
                      severity: 'green',
                      metadata: {
                        reason: action.endPopup
                      }
                    }
                  });
                  // eslint-disable-next-line
                  chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: action }, actionResponse => {
                    // if an action has a "respondTo" field, that means that we need to put
                    // the response object in the action array that correlates to the respondTo
                    // field. However, we also need to make sure that the "respondTo" is not
                    // the same as the "executor" or this could create an endless cycle
                    // (meaning, an executor cannot respond to itself... because that would trigger
                    // another action)
                    if (actionResponse && action.respondTo && action.respondTo !== action.executor) {
                      syncClient.addAction(actionTypes.response.sendResponse(action.action, action.respondTo, actionResponse));
                    }
                  });
                });
              } else {
                // send the action to the extension
                // eslint-disable-next-line
                chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: action }, actionResponse => {
                  // if an action has a "respondTo" field, that means that we need to put
                  // the response object in the action array that correlates to the respondTo
                  // field. However, we also need to make sure that the "respondTo" is not
                  // the same as the "executor" or this could create an endless cycle
                  // (meaning, an executor cannot respond to itself... because that would trigger
                  // another action)
                  if (actionResponse && action.respondTo && action.respondTo !== action.executor) {
                    syncClient.addAction(actionTypes.response.sendResponse(action.action, action.respondTo, actionResponse));
                  }
                });
              }
            }
          });
          // END EXTENSION ACTIONS
          // Now, we will setup the listener for the actions that this UI is able to take
          // START SESSION ACTIONS
          syncClient.getEventEmitter().on("sessionActionsAdded", function(actions) {
            for (const action of actions) {
              switch (action.action) {
                case "prepareAndStartRecording":
                  beginRecording(action);
                  break;
                case "getChromeDesktopId":
                  startRecording(action);
                  break;
                case "createTaskForStudent":
                  createTaskForStudent(action);
                  break;
                case "createAnnotation":
                  createAnnotation(action);
                  break;
                case "startUtilityApp":
                  // First, we will try starting the background check
                  try {
                    startBackgroundAppsCheck();
                  } catch (error) {
                    console.log('Utility App is not started...');
                  }
                  // Now, we will start the 10 second interval that hits the healthcheck. Even if the above call failed
                  // this function will attempt to restart the utility app if it was not started before
                  startUtilityAppHealthcheck();
                  break;
                case "startKillingBlockedApps":
                  try {
                    startKillingApps();
                  } catch (error) {
                    console.log('Utility App not killing apps...');
                  }
                  break;
                case "rtmpHlsServerReady":
                  console.log('Got action to start RTMP!', {action, liveswitchChannel});
                  if (liveswitchChannel) {
                    const channelConfig = new liveswitch.ChannelConfig();
                    channelConfig.setEnableRtmp(true);
                    liveswitchChannel.update(channelConfig);
                  }
                  break;
                default:
                  break;
              }
            }
          });
          // END SESSION ACTION
          // Now we have to setup the listener for if a session is closed out from the ghost
          syncClient.getEventEmitter().on("spSessionForceClosed", function() {
            // This means that the student did not choose to end their session, but something else did (ghost)
            stop().then(() => {
              createAnnotation({
                smarterProctoringAnnotationData: {
                  title: 'User Disconnected',
                  severity: 'green',
                  metadata: {
                    reason: 'User Lost Connection to the Session'
                  }
                }
              });
              // Create an action to tell the extension to end the session
              syncClient.addAction(actionTypes.extension.endSession("UnstableInternet"));
            });
          });
          // We need to have a listener ready for when a student presses something on the floating menu. Many of these actions
          // require sync doc information, so this will add an action when needed
          window.addEventListener('message', chromeExtensionMessageListener, false);

          // Now, lets check if we should setup twilio conversations chat with the proctor (hybrid only)
          const workflowModule = sessionInfo?.session?.workflowModule || null;
          if (workflowModule && workflowModule.includes('hybrid')) {
            // we are in a hybrid session, so we need to setup the twilio conversation
            // start by getting the token we used for the sync client
            const twilioToken = syncClient.getSyncToken();
            const conversationsClient = await Client.create(twilioToken);

            const reinit = async () => {
              const twilioToken = await getSessionSyncToken(spToken);
              conversationsClient.updateToken(twilioToken);
            };

            conversationsClient.on('tokenAboutToExpire', reinit);
            conversationsClient.on('tokenExpired', reinit);

            conversationsClient.on('connectionStateChanged', state => {
              switch (state) {
                case 'connecting':
                  console.log('Connecting to conversation...');
                  break;
                case 'connected':
                  console.log('Successfully connected to conversation!');
                  break;
                case "disconnecting":
                  console.log('Disconnecting from conversation...');
                  break;
                case "disconnected":
                  console.log('Successfully disconnected from conversation!');
                  break;
                case "denied":
                  console.log('Failed to connect to conversation');
                  break;
                default:
                  break;
              }
            });
            conversationsClient.on('conversationJoined', async conversation => {
              // once we join a conversation, we need to handle setting it all up
              if (conversation.uniqueName === sessionSid) {
                // We joined a conversation that already has messages, so lets get those message to put into the chat
                const messages = await conversation.getMessages();
                // eslint-disable-next-line
                conversationMessages = await convertAllMessagesToArray(messages);
                if (conversationMessages) {
                  chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: actionTypes.extension.initializeAllChatMessages(conversationMessages) });
                }
                // eslint-disable-next-line
                twilioConversation = conversation;

                twilioConversation.on('messageAdded', async message => {
                  // When the conversation receives a message, we need to post it to the extension so it can add it
                  // to the injected version of the chat
                  const incomingMessageAuthor = await getAuthorNameString(message.state.author);
                  const newMessage = {
                    twilioMessage: message.state.body,
                    author: incomingMessageAuthor,
                    timestamp: message.state.timestamp
                  };
                  if (message.state.body.startsWith("<<>>*")) {
                    // If we receive a message that starts with this, they are known as our "control characters" and they
                    // should not be displayed in the chat... but are used for actions.
                    // TODO: consider moving these into extension actions in the future
                    let event = { type: 'placeholder' };
                    try {
                      const base64Message = message.state.body.replace("<<>>*", "").trim();
                      const decodedMessage = atob(base64Message);
                      event = JSON.parse(decodedMessage);
                    } catch (error) {
                      console.log('A message came with our control characters, but is in the wrong form:', { message, error});
                    }

                    if (event.type !== 'event') {
                      return;
                    }

                    switch(event.content.title) {
                      case "Proctor Voice Chat Initializing":
                        //syncClient.addAction(actionTypes.extension.alertForProctorVoiceChat("voiceChatInitializing"));
                        chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
                            action: 'alertForProctorVoiceChat',
                            alertTitle: 'voiceChatInitializing',
                            executor: 'extension',
                            type: 'action'
                          }});
                        break;
                      case "Proctor Voice Chat Started":
                        //syncClient.addAction(actionTypes.extension.alertForProctorVoiceChat("voiceChatStarted"));
                        chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
                            action: 'alertForProctorVoiceChat',
                            alertTitle: 'voiceChatStarted',
                            executor: 'extension',
                            type: 'action'
                          }});
                        break;
                      case "Proctor Voice Chat Ended":
                        //syncClient.addAction(actionTypes.extension.alertForProctorVoiceChat("voiceChatEnded"));
                        chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
                            action: 'alertForProctorVoiceChat',
                            alertTitle: 'voiceChatEnded',
                            executor: 'extension',
                            type: 'action'
                          }});
                        break;
                      default:
                        break;
                    }
                    return;
                  }
                  conversationMessages.push(newMessage);
                  //syncClient.addAction(actionTypes.extension.addConversationMessage(newMessage));
                  chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
                      action: 'addConversationMessage',
                      chatMessage: newMessage,
                      executor: 'extension',
                      type: 'action'
                    }});
                });

                //syncClient.addAction(actionTypes.extension.conversationAvailabilityChange(true));
                chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
                    action: 'conversationAvailabilityChange',
                    available: true,
                    executor: 'extension',
                    type: 'action'
                  }});
              }
            });

            conversationsClient.on('conversationLeft', async conversation => {
              if (conversation.uniqueName === sessionSid) {
                conversationMessages = [];
                twilioConversation = null;
                // eslint-disable-next-line
                authorMap = {};
                //syncClient.addAction(actionTypes.extension.conversationAvailabilityChange(false));
                chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
                    action: 'conversationAvailabilityChange',
                    available: false,
                    executor: 'extension',
                    type: 'action'
                  }});
              }
            });
          }
        }
        setupSyncClient().then(() => {
          // Now that everything is setup and we have session start, lets raise the session start annotation
          createAnnotation({
            smarterProctoringAnnotationData: {
              title: 'Session Start',
              severity: 'green',
              metadata: {
                sessionStart: sessionInfo
              }
            }
          });
          //syncClient.addAction(actionTypes.extension.changeIconColor('green'));
          chrome.runtime.sendMessage(config.extensionId, { message: 'sp_background__takeAction', actionData: {
              action: 'changeIconColor',
              color: 'green',
              executor: 'extension',
              type: 'action'
            }});
        });
      }
    });

  }, []);
  // Make sure we have params, or do not render session components
  if (!token || !sessionSid || !examSid || !installSid) {
    return (
        <>
          {translation("general.missing_url_params")}
        </>
    );
  }


  return(
    <>
    <LaunchDarkly clientId={config.ldApiKey} user={ldConfig}>
      {currentTab !== 'screenSharing' &&
        <div className="nav-menu">
          <div>
            <button onClick={activateAttachmentTab} focused={currentTab === 'attachments' ? 'true' : 'false'}>Attachments</button>
            <button onClick={activateSessionDetailsTab} focused={currentTab === 'sessionDetails' ? 'true' : 'false'}>Session Details</button>
            <button onClick={activateToolsTab} focused={currentTab === 'tools' ? 'true' : 'false'}>Virtual Calculator</button>
          </div>
          <button id="spExam" onClick={returnToExam}>Return to Exam</button>
        </div>
      }
        <div id="spTabs">
          {tabs[currentTab]}
        </div>
        <div id="spVirtualCalc">
          <div className="calc__body" id="calculator"/>
        </div>
        <div id="spSupportChat">
          <FeatureFlag
            flagKey='salesforce-chat'
            renderFeatureCallback={() => renderSupportChat(true)}
            renderDefaultCallback={() => renderSupportChat(false)}
          />
        </div>
      </LaunchDarkly>
    </>
  );
}